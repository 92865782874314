import baseButton from './components/baseButton.vue'
import baseButtonDropDown from './components/baseButtonDropDown.vue'
import baseButtonDropDownItem from './components/baseButtonDropDownItem.vue'
// import baseComment from './components/baseComment.vue'
import baseCard from './components/baseCard.vue'
import baseField from './components/baseField.vue'
import baseForm from './components/baseForm.vue'
import baseFormCustom from './components/baseFormCustom.vue'
import baseLoader from './components/baseLoader.vue'
import baseModal from './components/baseModal.vue'
import baseModalStatic from './components/baseModalStatic.vue'
import basePageTitle from './components/basePageTitle.vue'
import baseTable from './components/baseTable.vue'
import baseTabs from './components/baseTabs.vue'
import baseToast from './components/baseToast.vue'
import baseListInput from './components/baseListInput.vue'
import baseGeolocator from './components/baseGeolocator.vue'
import baseCodeViewer from './components/baseCodeViewer.vue'
import baseList from './components/baseList.vue'
import baseCollapse from './components/baseCollapse.vue'
// import baseDateInput from './components/baseDateInput.vue'
import basePill from './components/basePill.vue'
import baseTooltip from './components/baseTooltip.vue'
import featherIcon from './components/baseFeatherIcon.vue'
import baseDateInput from './components/form/date/baseDateInput.vue'

import stringField from './components/form/stringField.vue'

export default {
  baseButton,
  baseButtonDropDown,
  baseButtonDropDownItem,
  baseCard,
  baseField,
  baseForm,
  baseFormCustom,
  baseLoader,
  baseModal,
  basePageTitle,
  baseTable,
  baseTabs,
  baseToast,
  baseListInput,
  baseGeolocator,
  stringField,
  baseCodeViewer,
  baseList,
  baseCollapse,
  baseDateInput,
  basePill,
  baseTooltip,
  baseModalStatic,
  featherIcon,
}
