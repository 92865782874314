import lookupHandler from '@/use/lookupHandler'
import filterHandler from '@/use/filterHandler'
import translationHandler from '@/plugins/i18n/translationHandler'

export default () => {
  const useLookup = lookupHandler()
  const useFilter = filterHandler()
  const useTranslate = translationHandler()

  function getOptions(type, data, title) {
    const selector = {
      Electricity: {
        chart: {
          type: 'column',
          marginTop: 60,
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.electricity.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: '',
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'kWh')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Color for the x-axis labels
            },
          },
          lineColor: 'rgb(156, 163, 175)', // Color for the x-axis line
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Sets the legend text color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'kWh')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Gas: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.gas.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Water: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.water.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'm3')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'm3')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      Heat: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.heat.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)', // X-axis line color
          labels: {
            style: {
              color: 'rgb(156, 163, 175)', // X-axis labels color
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)', // Y-axis line color
          labels: {
            formatter: function () {
              return `${useFilter.filter(this.value, 'GJ')}`
            },
            style: {
              color: 'rgb(156, 163, 175)', // Y-axis labels color
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)', // Legend labels color
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${useFilter.filter(this.point.y, 'GJ')}`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
      HeatCost: {
        chart: {
          type: 'column',
        },
        exporting: {
          enabled: false,
        },
        colors: [useLookup.utilities.heatcost.colour],
        credits: {
          enabled: false,
        },
        title: {
          text: null,
        },
        xAxis: {
          type: 'category',
          lineColor: 'rgb(156, 163, 175)',
          labels: {
            style: {
              color: 'rgb(156, 163, 175)',
            },
          },
        },
        yAxis: {
          title: {
            text: null,
          },
          lineColor: 'rgb(156, 163, 175)',
          labels: {
            formatter: function () {
              return `${this.value}`
            },
            style: {
              color: 'rgb(156, 163, 175)',
            },
          },
        },
        legend: {
          align: 'center',
          verticalAlign: 'bottom',
          x: 0,
          y: 0,
          floating: false,
          shadow: false,
          itemStyle: {
            color: 'rgb(156, 163, 175)',
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.point.name}<br>${this.series.name}: ${this.point.y} Eenheden`
          },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
            dataLabels: {
              enabled: false,
            },
          },
        },
        series: [
          {
            name: `${useTranslate.t(title)}`,
            data: data,
          },
        ],
      },
    }

    return selector[type]
  }

  function getScatterOptions(medium, data, regressionLines, splitDate = null) {
    const mediumConfig = {
      Electricity: {
        color: 'rgba(255, 204, 0, 0.5)',
        yAxisText: 'Verbruik (kWh)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} kWh',
        unit: 'kWh',
        regressionColor: 'rgba(255, 204, 0, 0.7)',
      },
      Gas: {
        color: 'rgba(174, 204, 83, 0.5)',
        yAxisText: 'Verbruik (m3)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} m3',
        unit: 'm3',
        regressionColor: 'rgba(174, 204, 83, 0.8)',
      },
      Water: {
        color: 'rgba(148, 206, 242, 0.5)',
        yAxisText: 'Verbruik (m3)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} m3',
        unit: 'm3',
        regressionColor: 'rgba(148, 206, 242, 0.7)',
      },
      Heat: {
        color: 'rgba(237, 106, 90, 0.5)',
        yAxisText: 'Verbruik (GJ)',
        tooltipFormat: '{point.date} TG: {point.x}, Verbruik: {point.y} GJ',
        unit: 'GJ',
        regressionColor: 'rgba(237, 106, 90, 0.7)',
      },
    }

    const config = mediumConfig[medium] || mediumConfig.Electricity

    let series = []

    const comparisoncolour = 'rgba(145, 145, 145, 0.5)'

    if (splitDate) {
      // Split plot scenario
      series = [
        {
          name: 'Verbruik voor splitsdatum',
          data: data.before.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: comparisoncolour,
        },
        {
          name: 'Verbruik na splitsdatum',
          data: data.after.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: config.color,
        },
        {
          name: `Regressielijn ${medium} (voor)`,
          type: 'line',
          data: regressionLines.before,
          color: comparisoncolour,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
        {
          name: `Regressielijn ${medium} (na)`,
          type: 'line',
          data: regressionLines.after,
          color: config.regressionColor,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
      ]
    } else {
      // Single plot scenario
      series = [
        {
          name: 'Verbruik per buitentemperatuur',
          data: data.map((point) => ({
            date: point[0],
            x: point[1],
            y: point[2],
          })),
          color: config.color,
        },
        {
          name: `Regressielijn ${medium}`,
          type: 'line',
          data: regressionLines,
          color: config.regressionColor,
          lineWidth: 2,
          dashStyle: 'Dash',
          marker: { enabled: false },
          states: { hover: { lineWidth: 2 } },
          enableMouseTracking: false,
        },
      ]
    }

    return {
      chart: { type: 'scatter', zoomType: 'xy' },
      exporting: { enabled: false },
      credits: { enabled: false },
      title: { text: `` },
      xAxis: {
        title: { text: 'Buitentemperatuur (°C)', style: { color: 'rgb(156, 163, 175)' } },
        type: 'category',
        lineColor: 'rgb(156, 163, 175)',
        labels: { style: { color: 'rgb(156, 163, 175)' } },
      },
      yAxis: {
        title: { text: null },
        lineColor: 'rgb(156, 163, 175)',
        labels: {
          formatter: function () {
            return `${this.value} ${config.unit}`
          },
          style: { color: 'rgb(156, 163, 175)' },
        },
      },
      plotOptions: {
        scatter: {
          tooltip: {
            headerFormat: '<b>{series.name}</b><br>',
            pointFormat: config.tooltipFormat,
          },
          marker: {
            symbol: 'circle', // Set the marker symbol to 'circle' for all series
            radius: 4, // Set the marker size (adjust as needed)
          },
        },
      },
      series: series,
      legend: {
        align: 'center',
        verticalAlign: 'bottom',
        x: 0,
        y: 0,
        floating: false,
        shadow: false,
        itemStyle: { color: 'rgb(156, 163, 175)' },
      },
    }
  }

  return {
    getOptions,
    getScatterOptions,
  }
}
