<template>
  <div class="text-gray-500">
    <div class="flex flex-row justify-between items-center">
      <!-- label -->
      <div class="align-middle">
        <span v-if="label">{{ $translate(label) }}</span>
      </div>

      <!-- options -->
      <div class="flex flex-row">
        <base-button
          variant="neutral"
          :icon="'SettingsIcon'"
          class="text-gray-500 mr-2"
          v-if="scatter"
          @click="toggleChart"
        >
          <scatterPlotIcon class="w-5 h-5 text-gray-500" />
        </base-button>

        <base-button-drop-down
          :alignRight="true"
          :handler="optionsDropDown"
          variant="neutral"
          :icon="'SettingsIcon'"
          class="mr-2"
        >
          <base-button-drop-down-item @click="seriesToExcel()">
            <div class="flex flex-row">
              <featherIcon class="mr-2 w-4 h-4 text-gray-500" icon="DownloadIcon" />
              <p class="whitespace-nowrap my-auto">Download xlsx</p>
            </div>
          </base-button-drop-down-item>
        </base-button-drop-down>

        <base-button variant="neutral" :icon="'MaximizeIcon'" class="text-gray-500" @click="openModal">
          <featherIcon class="w-4 h-4 text-gray-500" icon="MaximizeIcon" />
        </base-button>
      </div>
    </div>

    <div v-if="!isScatterPlot">
      <vue-highcharts
        class="mt-2"
        type="chart"
        :options="options"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="root"
      />
    </div>

    <div v-else>
      <vue-highcharts
        class="mt-2"
        type="chart"
        :options="scatterOptions"
        :redrawOnUpdate="true"
        :oneToOneUpdate="false"
        :animateOnUpdate="true"
        ref="scatterRoot"
      />
    </div>

    <!-- Chart Modal -->
    <base-modal v-if="showModal" @close="closeModal" :title="label || 'Chart'" size="xl" height="l">
      <div class="w-full h-full">
        <vue-highcharts
          class="h-full"
          type="chart"
          :options="isScatterPlot ? scatterOptions : options"
          :redrawOnUpdate="true"
          :oneToOneUpdate="false"
          :animateOnUpdate="true"
        />
      </div>
    </base-modal>
  </div>
</template>

<script>
import { nextTick, ref, watch } from 'vue'
import VueHighcharts from '@/use/highCharts'
import baseButtonDropDownHandler from '@/use/baseButtonDropDownHandler'
import ExcelJS from 'exceljs'
import scatterPlotIcon from '@/components/svg/scatterPlotIcon.vue'

export default {
  props: {
    label: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => {},
    },
    exportUnit: {
      type: String,
      default: '',
    },
    scatter: {
      type: Boolean,
      default: false,
    },
    scatterOptions: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const optionsDropDown = baseButtonDropDownHandler()
    const isScatterPlot = ref(false)
    const showModal = ref(false)

    // Watch the scatter prop and toggle back to normal graph if scatter is set to false
    watch(
      () => props.scatter,
      (newVal) => {
        if (!newVal && isScatterPlot.value) {
          isScatterPlot.value = false
        }
      },
    )

    function toggleChart() {
      isScatterPlot.value = !isScatterPlot.value
    }

    async function seriesToExcel() {
      await nextTick()
      const series = isScatterPlot.value ? props.scatterOptions.series : props.options.series
      const data = series[0].data
      const workbook = new ExcelJS.Workbook()
      const worksheet = workbook.addWorksheet('My Data')

      // Define columns dynamically based on the chart type
      let columns = []
      if (isScatterPlot.value) {
        columns = [
          { header: 'Datum', key: 'date', width: 15 },
          { header: 'Temperatuur', key: 'temperature', width: 15 },
          { header: 'Waarde', key: 'value', width: 10 },
          { header: 'Eenheid', key: 'unit', width: 10 },
        ]
      } else {
        columns = [
          { header: 'Datum', key: 'date', width: 15 },
          { header: 'Waarde', key: 'value', width: 10 },
          { header: 'Eenheid', key: 'unit', width: 10 },
        ]
      }
      worksheet.columns = columns

      // Add data rows to the worksheet
      data.forEach((row) => {
        if (isScatterPlot.value) {
          // Assuming the scatter plot data includes date information
          worksheet.addRow({
            date: row.date, // You'll need to ensure this data is available
            temperature: row.x,
            value: row.y,
            unit: props.exportUnit,
          })
        } else {
          worksheet.addRow({
            date: row[0],
            value: row[1],
            unit: props.exportUnit,
          })
        }
      })

      // Use a Blob to store the data and trigger a download
      workbook.xlsx
        .writeBuffer()
        .then((buffer) => {
          const blob = new Blob([buffer], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          })
          const url = window.URL.createObjectURL(blob)
          const anchor = document.createElement('a')
          anchor.href = url
          anchor.download = 'Simpleweg - export.xlsx'
          anchor.click()

          // Clean up
          window.URL.revokeObjectURL(url)
          anchor.remove()
        })
        .catch((error) => console.error('Something went wrong', error))
    }

    function openModal() {
      showModal.value = true
    }

    function closeModal() {
      showModal.value = false
    }

    return {
      optionsDropDown,
      seriesToExcel,
      toggleChart,
      isScatterPlot,
      openModal,
      closeModal,
      showModal,
    }
  },
  components: {
    VueHighcharts,
    scatterPlotIcon,
  },
}
</script>
